import styled from "styled-components"
import background from "@/public/header-background.svg"
import { Button } from "@mui/material"
import { ArrowRight } from "react-bootstrap-icons"
import translate from "@/utils/translation"
import { Link } from "next-translate-routes"
import { useSession } from "next-auth/react"
import TagManager from "react-gtm-module"

const CTA = () => {
  const { data: session } = useSession()

  return (
    <Wrapper>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className="cta-content">
              {!session?.profile?.firstName ? (
                <>
                  <h5>{translate("homepage_CTA_title")} </h5>
                  <p>{translate("homepage_CTA_description")}</p>
                  <div className="d-flex justify-content-center">
                    <Link
                      href="/auth/register"
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "CTA_button",
                            user_id: session?.uid,
                          },
                        })
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="rounded-pill mt-2 d-flex"
                        size="large"
                        style={{ background: "white" }} // Set white background for the button
                      >
                        {translate("homepage_CTA_button")}
                        <ArrowRight className="ms-2" />
                      </Button>
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <h5>{translate("homepage_CTA_title_signed_in")}</h5>
                  <p>{translate("homepage_CTA_description_signed_in")}</p>
                  <div className="d-flex justify-content-center">
                    <Link
                      href="/listings"
                      onClick={() => {
                        TagManager.dataLayer({
                          dataLayer: {
                            event: "CTA_button",
                            user_id: session?.uid,
                          },
                        })
                      }}
                    >
                      <Button
                        variant="outlined"
                        className="rounded-pill mt-2 d-flex"
                        size="large"
                        style={{ background: "white" }} // Set white background for the button
                      >
                        {translate("homepage_CTA_button_signed_in")}
                        <ArrowRight className="ms-2" />
                      </Button>
                    </Link>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}

export default CTA

const Wrapper = styled.div`
  position: relative; /* Ensure positioning context for :before */
  padding: 4rem 0;
  background: #fb8500;
  color: white;
  text-align: center;
  overflow: hidden; /* Ensure background doesn't overflow */

  h5 {
    font-size: 1.75rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1.5rem;
      line-height: 2.3rem;
    }
  }

  p {
    font-size: 1rem;
    margin-bottom: 1rem;

    @media (max-width: 768px) {
      font-size: 1rem;
      padding: 0 0.5rem;
    }
  }

  .container:before {
    content: "";
    background-image: url(${background.src});
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    opacity: 1;
    top: -200px;
    scale: 3;
    rotate: -20deg;
  }

  a {
    text-decoration: none;
  }
`
