import { MuiPhone } from "@/components/common/phoneInput/phoneInput"
import { Context } from "@/context/state"
import translate from "@/utils/translation"
import { Avatar, ClickAwayListener } from "@mui/material"
import axios from "axios"
import { useSession } from "next-auth/react"
import React, { useContext, useRef, useState } from "react"
import { Cash, CashCoin, Clock, Phone, X } from "react-bootstrap-icons"
import TagManager from "react-gtm-module"
import { FaPhoneAlt } from "react-icons/fa"
import {
  MdLocalPhone,
  MdOutlinePriceCheck,
  MdPriceChange,
} from "react-icons/md"
import styled from "styled-components"

const Appointment = ({ setIsComponentVisible, isComponentVisible }) => {
  const { state, dispatch } = useContext(Context)
  const listing = state?.listing

  const ref = useRef()

  const { data: session } = useSession()

  const [phone, setPhone] = useState(session?.profile?.phone)

  const [errors, setErrors] = useState({})
  const [finished, setFinished] = useState(true)

  const phoneValid = translate("account_my_settings_phone_wrong")

  const handleCall = async () => {
    if (phone.length < 8) {
      setErrors({ phone: phoneValid })
      return
    } else if (phone.length > 15) {
      setErrors({ phone: phoneValid })
      return
    } else {
      try {
        TagManager.dataLayer({
          dataLayer: {
            event: "listing_appointment_success",
            user_id: session?.uid,
            page: "listing",
          },
        })

        setErrors({})

        setFinished(false)

        const body = {
          phone: phone,
          name: session?.profile?.firstName + " " + session?.profile?.lastName,
          linkedIn: session?.profile?.linkedIn || undefined,
          role: session?.profile?.role || undefined,
          email: session?.email || undefined,
          property: listing?.slug || "homepage",
          propertyLink: listing
            ? `https://nextvalvest.com/listing/${listing?.slug}`
            : "https://nextvalvest.com",
        }

        const url = `${process.env.NEXT_PUBLIC_HOST_URL}/api/callback`

        await axios.post(url, body, {
          headers: {
            Authorization: `bearer ${process.env.STRAPI_KEY}`,
          },
        })
      } catch (error) {
        console.error(error)
      }

      //sent email to admin with phone number and property link
    }
  }

  return (
    <div ref={ref}>
      {isComponentVisible && (
        <Wrapper>
          <div className="inner">
            <div className="hide">
              <X
                size={30}
                color="black"
                className="clickable"
                onClick={() => {
                  setIsComponentVisible(false), setFinished(true)
                }}
              />
            </div>
            {finished ? (
              <>
                <div className="d-flex">
                  <Avatar
                    className="me-3"
                    sx={{ width: 40, height: 40, marginTop: "0rem" }}
                    src="/pages/about/employees/Jordan.jpg"
                  />
                  <h4 className="fw-normal">
                    {translate("listing_modal_appointment_title")}
                  </h4>
                </div>
                <p className="mt-2">
                  {translate("listing_modal_appointment_text")}
                </p>
                <p>{translate("listing_modal_appointment_text_2")} </p>
                <MuiPhone
                  className="mt-3"
                  InputLabelProps={{ shrink: true }}
                  autoComplete="false"
                  error={errors?.phone}
                  helperText={errors?.phone}
                  onChange={e => setPhone(e)}
                  value={phone}
                  label={translate("account_my_settings_profile_phone")}
                  fullWidth
                  required
                  minLength={3}
                />
                <div className="callback">
                  <div className="item d-flex">
                    <Clock />{" "}
                    <p>{translate("listing_modal_appointment_usp_1")}</p>
                  </div>
                  <div className="item d-flex">
                    <MdLocalPhone />{" "}
                    <p>{translate("listing_modal_appointment_usp_2")}</p>
                  </div>
                  <div className="item d-flex">
                    <Cash />{" "}
                    <p>{translate("listing_modal_appointment_usp_3")}</p>
                  </div>
                </div>
                <div
                  className="btn btn-primary call w-100 mt-3"
                  onClick={() => handleCall()}
                >
                  {translate("listing_modal_appointment_call")}
                </div>
              </>
            ) : (
              <>
                <h4 className="fw-normal">
                  {translate("listing_modal_appointment_success")}
                </h4>
                <p>{translate("listing_modal_appointment_success_text")}</p>
                <div
                  className="btn btn-primary call w-100 mt-3"
                  onClick={() => {
                    setIsComponentVisible(false), setFinished(true)
                  }}
                >
                  {translate("listing_edit_document_close")}
                </div>
              </>
            )}
          </div>
        </Wrapper>
      )}
    </div>
  )
}

export default Appointment

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgb(106, 106, 111, 0.2);
  z-index: 999999;

  .inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    z-index: 9999999;
    background-color: white;
    border-radius: 20px;
    padding: 2rem;

    .hide {
      position: absolute;
      top: 15px;
      right: 15px;
    }
  }

  p {
    font-size: 0.875rem;
  }

  .callback {
    margin-top: 1rem;
    background-color: #fafafc;
    border-radius: 10px;
    padding: 1rem 1rem 0;
    font-weight: 300;

    .item {
      svg {
        color: black !important;
        margin-top: 3px;
      }
      p {
        margin-left: 10px;
        margin-bottom: 0.4rem;
      }
    }
  }

  .call {
    border-radius: 15px;
  }
`
